<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <DxDataGrid
          v-if="can_list"
          id="site-sub-contractor-user-table"
          ref="data-grid"
          width="100%"
          :show-borders="true"
          :data-source="databoq"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :hover-state-enabled="true"
          key-expr="id"
          exporting="exportGrid"
          @row-inserted="onInserted"
          @row-updated="onUpdated"
          @exporting="onExporting"
          @edit-canceling="userEditClickCancel"
          @edit-canceled="userEditClickCancel"
          @init-new-row="userEditClick"
        >
          <DxColumn data-field="id" caption="ID" :allow-editing="false" />
          <DxColumn
            type="buttons"
            :allow-exporting="false"
            :caption="$t('table.actions')"
          >
            <DxButton name="edit" />
          </DxColumn>
          <DxColumn
            data-field="firstname"
            :name="this.$t('user.name')"
            :caption="this.$t('user.name')"
          />
          <DxColumn
            data-field="lastname"
            :name="this.$t('user.lastName')"
            :caption="this.$t('user.lastName')"
          />
          <DxColumn
            data-field="username"
            :name="this.$t('user.username')"
            :caption="this.$t('user.username')"
            :allow-editing="false"
          />
          <DxColumn
            data-field="password"
            :name="this.$t('user.password')"
            :caption="this.$t('user.password')"
          />
          <DxColumn
            data-field="is_active"
            :name="this.$t('user.is_active')"
            :caption="this.$t('user.is_active')"
          />
          <DxColumn
            data-field="mobile_auth"
            :name="this.$t('user.mobile_auth')"
            :caption="this.$t('user.mobile_auth')"
          />
          <DxColumn
            data-field="web_auth"
            :name="this.$t('user.web_auth')"
            :caption="this.$t('user.web_auth')"
          />
          <DxColumn
            data-field="role_id"
            :name="this.$t('user.role_id')"
            :caption="this.$t('user.role_id')"
          >
            <DxLookup
              :data-source="dataRole"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            data-field="position_id"
            :name="this.$t('user.position_id')"
            :caption="this.$t('user.position_id')"
          >
            <DxLookup
              :data-source="dataPosition"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
              data-field="data_entry"
              :name="this.$t('user.data_entry')"
              :caption="this.$t('user.data_entry')"
          />
          <DxFilterRow :visible="true" />
          <DxSearchPanel :visible="true" />
          <DxColumnFixing :enabled="true" />
          <DxColumnChooser :enabled="true" />
          <DxScrolling mode="standard" />
          <DxGrouping :context-menu-enabled="true" />
          <DxGroupPanel :visible="true" />
          <DxPaging :page-size="20" />

          <DxPager
            :visible="true"
            :allowed-page-sizes="pageSizes"
            :display-mode="displayMode"
            :show-page-size-selector="showPageSizeSelector"
            :show-info="showInfo"
            :show-navigation-buttons="showNavButtons"
          />
          <DxExport
            :enabled="true"
            :visible="true"
            :allow-export-selected-data="false"
          />
          <DxEditing
            :allow-updating="can_edit"
            :allow-deleting="can_delete"
            :allow-adding="can_create"
            mode="row"
            :select-text-on-edit-start="true"
            :use-icons="true"
          />
        </DxDataGrid>
        <h1 v-else>{{$t("noPermission")}}</h1>
        <DxPopup
          :visible="deletePop"
          :close-on-outside-click="false"
          :show-close-button="false"
          :title="$t('deletingUser')"
        >
          <div class="dx-field">
            <div class="dx-field-label">Name</div>
            <div class="dx-field-value">
              <DxTextBox
                v-model="rowData.firstname"
                :read-only="true"
              ></DxTextBox>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Last Name</div>
            <div class="dx-field-value">
              <DxTextBox
                v-model="rowData.lastname"
                :read-only="true"
              ></DxTextBox>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ $t('user.your_password') }}</div>
            <div class="dx-field-value">
              <DxTextBox v-model="password" mode="password"></DxTextBox>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-value">
              <b-button
                class="btn-import mr-1"
                variant="danger"
                @click="closeAddingPop()"
                >Close</b-button
              >
              <b-button
                class="btn-import mr-1"
                variant="success"
                @click="deleteUserFN()"
                >Delete User</b-button
              >
            </div>
          </div>
        </DxPopup>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios';
import { BCard, BButton } from 'bootstrap-vue';
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxPaging,
  DxEditing,
  DxExport,
  DxLookup,
  DxButton,
  DxPager
} from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import {readOnly} from "@/auth/utils";

export default {
  components: {
    BCard,
    BButton,
    DxEditing,
    DxFilterRow,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxExport,
    DxLookup,
    DxButton,
    DxPopup,
    DxTextBox,
    DxPager
  },
  data() {
    return {
      displayMode : 'full',
      can_edit: false,
      can_list: false,
      can_create: false,
      can_delete: false,
      pageSizes: [20, 50, 100, 200],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      enterKeyActions: ['startEdit', 'moveFocus'],
      enterKeyDirections: ['none', 'column', 'row'],
      enterKeyDirection: 'row',
      enterKeyAction: 'moveFocus',
      databoq: [],
      dataPosition: [],
      dataRole: [],
      dataLoading: true,
      error: '',
      userEdit: false,
      rowData: '',
      deletePop: false,
      password: ''
    };
  },
  async mounted() {
      this.readOnly = readOnly();
    await this.getPageAuths();
    await this.getUserData();
    await this.getRoles();
    await this.getPositions();
  },
  methods: {
    closeAddingPop() {
      this.deletePop = false;
      this.rowData = '';
    },
    async deleteUserFN() {
      try {
        const result = await axios.post(
          `/delete-work-of-user/${this.rowData.id}`,
          { password: this.password }
        );
        this.closeAddingPop();
        if (result.status === 200) {
          this.showToast({
            variant: 'success',
            text: 'Kullanıcı verileri başarıyla silindi.'
          });
        } else {
          this.showToast({
            variant: 'danger',
            text: 'Kullanıcı verileri silinemedi.'
          });
        }
      } catch (e) {
        this.showToast({
          variant: 'danger',
          text: 'Kullanıcı verileri silinemedi.'
        });
      }
    },
    onAssign(e) {
      this.deletePop = true;
      this.rowData = e.row.data;
    },
    async getPageAuths() {
      const pageAuths = await axios.post('/get-page-auth', { page: 'User' });
      this.can_edit =this.readOnly && pageAuths.data.can_edit;
      this.can_list = pageAuths.data.can_list;
      this.can_delete = this.readOnly &&pageAuths.data.can_delete;
      this.can_create = this.readOnly &&pageAuths.data.can_create;
    },
    async getRoles() {
      this.error = '';
      const response = await axios.get('/list-role');

      try {
        this.dataRole = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPositions() {
      this.error = '';
      const response = await axios.get('/list-position');

      try {
        this.dataPosition = response?.data || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    userEditClick() {
      this.userEdit = true;
    },
    userEditClickCancel() {
      this.userEdit = false;
    },
    async getUserData() {
      this.dataLoading = true;
      this.error = '';
      const response = await axios.get('/list-site-user?listAll=true');

      try {
        this.databoq = response?.data?.result || [];
        this.dataLoading = false;
      } catch (error) {
        this.dataLoading = false;

        this.error = e?.response?.data?.message || e?.message;
      }
    },
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Fama-User');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: 'Arial', size: 12 };
          excelCell.alignment = { horizontal: 'left' };
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Fama-User.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    onUpdated(e) {
      const {
        firstname,
        lastname,
        password,
        is_active,
        mobile_auth,
        web_auth,
        role_id,
        chief_id,
        position_id,
        data_entry
      } = e.data;
      axios
        .post(`edit-site-user/${e.data.id}`, {
          firstname,
          lastname,
          password,
          is_active,
          mobile_auth,
          web_auth,
          role_id,
          chief_id,
          position_id,
          data_entry
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla güncellendi.'
          });
          this.getUserData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    },
    onInserted(e) {
      const { firstname, lastname, password, site: site_id, role_id ,position_id, data_entry } = e.data;

      axios
        .post('create-site-user', {
          firstname,
          lastname,
          password,
          site_id,
          role_id,
          position_id,
          data_entry
        })
        .then((response) => {
          this.showToast({
            variant: 'success',
            text: 'Veri başarıyla eklendi.'
          });
          this.getUserData();
        })
        .catch((err) => {
          this.showToast({
            variant: 'danger',
            text: err?.response?.data?.message || err?.message
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';

.dx-master-detail-cell {
  background-color: bisque !important;
}
</style>
